import React from 'react';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { Grid, Box, Link, Typography } from '@material-ui/core';
import SectionContainerLayout from '../../../components/section-container-layout/section-container-layout';
import CardTextHover from '../../../components/card-text-hover';
export default function Press() {
  return (
    <Layout>
      <SEO lang='en' title='Press Contacts | Global Manufacturing & Industrialisation Summit' />
      <SectionContainerLayout title='PRESS CONTACTS' baseLink='/press/contact' isDivider>
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <Box p={2}>
              <CardTextHover title='Reethu Thachil' description='Head of PR & Communications' />
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <Box p={2}>
              <CardTextHover title='Sylvia Sarkies' description='Associate PR & Communications Manager' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box p={2}>
              <CardTextHover title='Ahmad Huzayen' description='PR & Comms Manager' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box p={2}>
              <CardTextHover title='Indu Nandakumar' description='Content Writer' />
            </Box>
          </Grid> */}
        </Grid>
        <Box p={3}>
          <Typography variant='body1'>
            For media inquiry, please contact: <Link href='mailto:press@gmisummit.com'> press@gmisummit.com.</Link>
          </Typography>
        </Box>
      </SectionContainerLayout>
    </Layout>
  );
}

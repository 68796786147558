import React from 'react';
import './card-text-hover.scss';
import { Card, CardActionArea, CardContent, Typography, Box, makeStyles, createStyles } from '@material-ui/core';
import Fade from 'react-reveal/Fade';

import Clamp from 'react-multiline-clamp';

const useStyles = makeStyles((theme) =>
  createStyles({
    media: {
      height: 300,
      position: 'relative',
      zIndex: '10',
    },
    cornerCardBorder: {
      height: '300px',
      border: `5px solid ${theme.palette.background.default} !important`,
      borderRadius: '3px',
      backgroundColor: '#0F1647',
      '&:hover': {
        // transition: 'all 0.5s !important',
        borderColor: `${theme.palette.primary.main} !important`,
        '&::before': {
          backgroundColor: '#0F1647',
        },
        '&::after': {
          backgroundColor: '#0F1647',
        },
      },
    },
    titleHeight: {
      height: '55px',
    },
    bodyHeight: {
      height: '140px',
    },
    readMorePadding: {
      paddingLeft: '8px',
    },
  })
);

const CardImageTitleDescription = ({ title, description }) => {
  const classes = useStyles();

  return (
    <Fade bottom>
      <Card square={true} elevation={0} className='card-image-title-description'>
        <Box className={[classes.cornerCardBorder, 'cornerCardBorder'].join(' ')}>
          <Box className='borderGlow'></Box>
          <CardActionArea
            className='zIndex-1'
            style={{ cursor: 'default', height: '100%', padding: '10px' }}
            disableTouchRipple>
            <CardContent p={2} style={{ height: '100%', backgroundColor: '#0F1647' }}>
              <Typography color='textPrimary' gutterBottom>
                <Box fontWeight='fontWeightBold' className={`text-uppercase, ${classes.titleHeight}`}>
                  <Clamp lines={2}>{title}</Clamp>
                </Box>
              </Typography>
              <Typography color='textPrimary' gutterBottom>
                {description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Box>
      </Card>
    </Fade>
  );
};

export default CardImageTitleDescription;
